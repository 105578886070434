'use client';

import { ProductPrice, useCartLine } from '@shopify/hydrogen-react';

export function CartLinePrice({
    className = 'text-lg lg:text-2xl font-heading font-bold lg:col-span-3 mb-2 lg:mb-0',
}: {
    className?: string;
}) {
    const { merchandise } = useCartLine();
    if (!merchandise?.price) return null;
    const compareAt = merchandise.compareAtPrice ? merchandise.compareAtPrice : undefined;
    return (
        <ProductPrice
            data={{
                priceRange: { minVariantPrice: merchandise.price },
                compareAtPriceRange: { minVariantPrice: compareAt },
                ...merchandise,
                __typename: 'Product',
            }}
            className={className}
        />
    );
}
